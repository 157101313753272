/**
 * Custom Component - Team Members
 * @author Charles Harwood
 */
/**
 * Grid Variables - CH
 */
/**
 * Custom SASS Mixins - CH
 */
.team-members {
  font-size: 2em;
  margin-bottom: 7em;
}

.team-members > * {
  text-align: left;
}

.team-members__grid {
  max-width: 108rem;
  margin: 0 auto;
}

.team-members__grid > .row {
  justify-content: center;
}

.team-members__grid h2.team-members-title {
  /**
      ** Added by Conn to close tag on OandI Base sites - it's only used in Office hubsite
      **/
  margin-bottom: 0;
}

.team-members__grid .team-member {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  padding-top: 8.5rem;
  padding-bottom: 1.5rem;
}

@media (max-width: 767px) {
  .team-members__grid .team-member {
    padding-top: 2rem;
    padding-bottom: 0.5rem;
  }
}

.team-members__grid .team-member > div {
  position: relative;
  width: auto;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.team-members__grid .team-member__image {
  flex-basis: 21rem;
  flex-grow: 0;
  flex-shrink: 1;
  max-width: 21rem;
}

.team-members__grid .team-member__image img {
  width: 100%;
}

.team-members__grid .team-member__details {
  flex-basis: calc(100% - 210px);
  flex-grow: 1;
  flex-shrink: 1;
  max-width: calc(100% - 210px);
}

.team-members__grid .team-member__details__name {
  margin-top: 0.5em;
  margin-bottom: 0.75em;
  font-size: 1.5em;
  font-weight: 700;
  line-height: 1em;
}

.team-members__grid .team-member__details__contact {
  align-items: center;
  margin-top: 2.1875em;
}

.team-members__grid .team-member__details__contact .col {
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  width: auto;
}

.team-members__grid .team-member__details__contact .team-member__details__phone,
.team-members__grid .team-member__details__contact .team-member__details__email {
  display: block;
  position: relative;
  margin-bottom: 0.625em;
  color: #ee2737;
  font-size: 1em;
  font-weight: 700;
  line-height: 1.25em;
}

.team-members__grid .team-member__details__contact .team-member__details__phone:before,
.team-members__grid .team-member__details__contact .team-member__details__email:before {
  display: inline-block;
  margin-top: -3px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100%;
  content: '';
  vertical-align: middle;
}

.team-members__grid .team-member__details__contact .team-member__details__phone a,
.team-members__grid .team-member__details__contact .team-member__details__email a {
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
}

.team-members__grid .team-member__details__contact .team-member__details__phone:before {
  width: 12px;
  height: 20px;
  background-image: url("../images/icons/icon-mobile.svg");
}

.team-members__grid .team-member__details__contact .team-member__details__email:before {
  width: 18px;
  height: 14px;
  background-image: url("../images/icons/icon-email.svg");
}

@media (max-width: 1199px) {
  .team-members__grid > .row {
    justify-content: center;
  }
  .team-members__grid .team-member {
    justify-content: flex-start;
  }
  .team-members__grid .team-member__details {
    flex-basis: auto;
    flex-grow: 0;
  }
}

@media (max-width: 767px) {
  .team-members__grid .team-member__image, .team-members__grid .team-member__details {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
